<template>
    <section class="login-area">
        <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
            <img src="assets/img/login-bg.jpg" alt="image" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
            <div class="d-table">
                <div class="d-table-cell">
                <div class="login-form">
                    <div class="logo">
                    <router-link :to="{ name: 'Home' }">
                        <img class="logo" src="@/assets/img/logo.png" alt="image"/>
                    </router-link>
                    </div>
                    <h3>Reset Password</h3>
                    <p>New to Raque? <router-link :to="{ name: 'TraineeRegister' }">Sign up</router-link></p>
                    <div class="card-body" v-if="enterPassword">
                        <form @submit.prevent="sendToken">
                            <input type="hidden" v-model="form.user_id">
                            <div class="form-group">
                                <input type="password" class="form-control ps-5" v-model="form.password" required="">
                                <div v-if="errors.password" class="text-small text-danger">{{ errors.password[0] }}</div>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control ps-5" v-model="form.password_confirmation" required="">
                                <div v-if="errors.password_confirmation" class="text-small text-danger">{{ errors.password_confirmation[0] }}</div>
                            </div>
                            <div class="d-grid gap-2">
                                <button class="btn default-btn" type="submit">Reset Password</button>
                            </div>
                            <div class="forgot-password">
                                <router-link :to="{ name: 'Login' }">Remember Password?</router-link>
                            </div>
                        </form>
                    </div>
                    <div class="card-body" v-else-if="tokenPresent">
                        <form @submit.prevent="validateToken">
                            <div class="form-group">
                                <p class="text-muted">Please enter your token you recieved through email.</p>
                                <input type="text" class="form-control ps-5" v-model="form.token" required="">
                                <div v-if="errors[0]" class="text-small text-danger">{{ errors }}</div>
                            </div>
                            
                            <div class="d-grid gap-2">
                                <button class="btn default-btn" type="submit">Validate Token</button>
                            </div>
                            <div class="forgot-password">
                                <router-link :to="{ name: 'Login' }">Remember Password?</router-link>
                            </div>
                        </form>
                    </div>
                    <div class="card-body" v-else>
                        <form @submit.prevent="resetPassword">
                            <div class="form-group">
                                <p class="text-muted">Please enter your email address. You will receive a token to create a new password via email.</p>
                                <input type="email" placeholder="Your email address" class="form-control" v-model="form.email"/>
                                <div v-if="errors[0]" class="text-small text-danger">{{ errors }}</div>
                            </div>
                            
                            <div class="d-grid gap-2">
                                <button class="btn default-btn" type="submit">Send</button>
                            </div>
                            <div class="forgot-password">
                                <router-link :to="{ name: 'Login' }">Remember Password?</router-link>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
    
</template>

<script>
import axios from 'axios'
export default {
  components: {  },
  data(){
        return{
            success: false,
            enterEmail: true,
            tokenPresent: false,
            enterPassword: false,
            isLoading: false,
            values: [],
            form:{
                email: '',
                token: '',
                password: '',
                password_confirmation: '',
                user_id: ''
            },
            
            user: {},
            errors: {}
        }
    },
    methods:{
        sendToken(){
            this.Loading = true
                axios.post('https://apitraining.vipawaworks.com/api/auth/user/reset_password',this.form).then(response =>{
                response
                this.$router.push({ name: 'Login'})
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
        validateToken(){
            this.Loading = true
                axios.post('https://apitraining.vipawaworks.com/api/auth/user/validate_token',this.form).then(response =>{
                this.success = response.data
                this.enterPassword = true
                this.form.user_id = response.data.id
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
        resetPassword(){
            this.Loading = true
            axios.post('https://apitraining.vipawaworks.com/api/auth/user/forgot_password',this.form).then(response =>{
               this.tokenPresent = true
                this.success = response.data
            }).catch((errors) => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
        document.title = `Forgot Password - VipawaWorks`
    }

}
</script>

<style>

</style>